@import "primeng/resources/themes/bootstrap4-dark-blue/theme.css";

$idb-dark-alpha: 0.8;
$idb-color-icon: #31282c;
$idb-color-disabled: rgba(white, $idb-dark-alpha);
$idb-color-true: #c5e1a5;
$idb-color-false: #ef9a9a;
$idb-color-unknown: rgba(#90caf9, 0);
$idb-color-bg-disabled: rgba(darkgray, $idb-dark-alpha);
$idb-color-bg-true: rgba($idb-color-true, $idb-dark-alpha);
$idb-color-bg-false: rgba($idb-color-false, $idb-dark-alpha);
$idb-color-bg-unknown: rgba($idb-color-unknown, $idb-dark-alpha);
$idb-stroke-color: white;
$idb-stroke-width: 0.1rem;

.header {
    .left-menu,
    .right-menu {
        background-color: #343e4d;
    }
}

.idb-invalid {
    border-color: #f19ea6;
    &:focus {
        border-color: #f19ea6;
    }
}

.idb-switch {
    :not(.readonly, .disabled) {
        &.slider {
            &.true {
                &:hover {
                    background-color: $idb-color-bg-false;
                }
            }

            &.false {
                &:hover {
                    background-color: $idb-color-bg-true;
                }
            }
        }
    }

    .disabled {
        &.slider {
            &.true,
            &.false {
                background-color: $idb-color-bg-disabled;
            }
        }

        &.button {
            &.true,
            &.false,
            &.unknown {
                color: $idb-color-disabled;
            }
        }
    }

    .slider {
        &.true {
            background-color: $idb-color-bg-true;
        }

        &.false {
            background-color: $idb-color-bg-false;
        }
    }

    .button {
        color: $idb-color-icon;
        border-color: $idb-color-icon;

        &.true {
            background-color: $idb-color-true;
        }

        &.false {
            background-color: $idb-color-false;
        }

        &.disabled {
            &.true,
            &.false,
            &.unknown {
                color: $idb-color-disabled;
                background-color: $idb-color-bg-disabled;
            }
        }
    }
}

.idb-stroke {
    text-shadow: 0 0 $idb-stroke-width $idb-stroke-color, 0 0 $idb-stroke-width $idb-stroke-color, 0 0 $idb-stroke-width $idb-stroke-color,
        0 0 $idb-stroke-width $idb-stroke-color, 0 0 4px $idb-stroke-color, 0 0 $idb-stroke-width $idb-stroke-color, 0 0 $idb-stroke-width $idb-stroke-color,
        0 0 $idb-stroke-width $idb-stroke-color, 0 0 $idb-stroke-width $idb-stroke-color, 0 0 4px $idb-stroke-color;
}

.idb-menuitem-active {
    background-color: rgba(darkgray, 0.2);
    border-bottom: 2px solid black;

    &:hover {
        > .p-menuitem-content {
            > .p-menuitem-link {
                > .p-menuitem-icon {
                    color: #8dd0ff !important;
                }

                > .p-menuitem-text {
                    color: lightgrey !important;
                }
            }
        }
    }

    > .p-menuitem-content {
        > .p-menuitem-link {
            > .p-menuitem-icon {
                color: #8dd0ff !important;
            }

            > .p-menuitem-text {
                color: lightgrey !important;
            }
        }
    }
}

.idb-menuitem-normal {
    > .p-menuitem-content {
        > .p-menuitem-link {
            > .p-menuitem-icon {
                color: #8dd0ff !important;
            }
        }
    }
}

.p-datatable .p-datatable-thead > tr:hover > th,
.p-datatable .p-datatable-tbody > tr:hover > td {
    background-color: #33403f;
}
